h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: Avenir, sans-serif;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@font-face {
	font-family: 'Futura Light';
	src: url(./Fonts/futura_light_regular-webfont.eot) format('eot');
	src: local('Futura Light'),
		url(./Fonts/futura_light_regular-webfont.woff) format('woff'),
		url(./Fonts/futura_light_regular-webfont.svg) format('svg'),
		url(./Fonts/futura_light_regular-webfont.woff2) format('woff2'),
		url(./Fonts/futura_light_regular-webfont.ttf) format('truetype');
}

@font-face {
	font-family: 'Avenir Light';
	src: url('./Fonts/avenir_light.woff2') format('woff2'),
		url('./Fonts/avenir_light.woff') format('woff');
}

@font-face {
	font-family: 'Avenir Bold';
	src: url('./Fonts/avenir_bold.woff2') format('woff2'),
		url('./Fonts/avenir_bold.woff') format('woff');
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
